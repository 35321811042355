import type { GetServerSideProps } from 'next';
import { getServerAxiosInstance } from '@flexteam/axios';
import { getTranslationsProps } from '@flexteam/i18n';
import { serverSideTranslations } from '@flexteam/i18n/server';
import type { RecruitingSiteResponse } from '@flexteam/apis-recruiting';
import { PageType } from '@flexteam/apis-recruiting';
import { generateSiteDesign } from '@flex-domains/recruiting-site-builder/base';

import type { WithErrorProps } from '../src';
import {
  getSubdomainFromServerSidePropsContext,
  fetchRecruitingSite,
  handleServerSideError,
  handleSystemSubdomain,
  useSendAccessLog,
  withErrorPage,
  MainPage,
} from '../src';

interface Props {
  recruitingSiteResponse: RecruitingSiteResponse;
}

function Main({ recruitingSiteResponse }: Props) {
  const siteDesign = generateSiteDesign(recruitingSiteResponse);

  useSendAccessLog({
    customerIdHash: siteDesign.customerIdHash,
    pageType: PageType.Site,
  });

  return <MainPage siteDesign={siteDesign} />;
}

const MainWithErrorPage = withErrorPage(Main);
export default MainWithErrorPage;

type ServerSideProps = WithErrorProps<Props>;
export const getServerSideProps: GetServerSideProps<
  ServerSideProps
> = async context => {
  try {
    const axiosInstance = getServerAxiosInstance(context);
    const subdomain = getSubdomainFromServerSidePropsContext(context);

    await handleSystemSubdomain({ context, axiosInstance, subdomain });

    const [recruitingSiteResponse, { props: translationProps }] =
      await Promise.all([
        fetchRecruitingSite(axiosInstance, { subdomain }),
        getTranslationsProps(serverSideTranslations, {
          appName: 'public-careers-team',
        })(context),
      ]);

    return {
      props: {
        recruitingSiteResponse,
        ...translationProps,
      },
    };
  } catch (error) {
    return handleServerSideError(context, error);
  }
};
